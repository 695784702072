<template>
  <section>
    <v-radio-group
        v-model="profile.isClinicNumber">
      <v-radio
          :label="$t('Clinic contact number')"
          :value="true"
          class="mb-2"
      ></v-radio>

      <v-radio
          :label="$t('My own contact number')"
          :value="false"
          class="mt-2"
      ></v-radio>
      <v-expand-transition>
        <section v-show="profile.isClinicNumber">
          <v-form ref="form" v-model="valid" lazy-validation class="d-flex flex-column mb-4">
            <span class="my-1 d-block">{{ $t('Phone Number') }}</span>
            <v-textarea outlined v-model="clinicNumber" readonly type="text" counter="1000" required/>
          </v-form>
        </section>
      </v-expand-transition>
      <v-expand-transition>
        <section v-show="!profile.isClinicNumber">
          <v-form ref="form" v-model="valid" lazy-validation class="d-flex flex-column mb-4">
            <span class="my-1 d-block">{{ $t('Phone Number') }}</span>
            <v-combobox
                outlined
                single-line
                v-model="phoneNumbers"
                :rules="[(v) => !!v]"
                required
                multiple
                append-icon=""
                :delimiters="[',', '-', '.']">
              <template v-slot:selection="data">
                <v-chip
                    small
                    dense
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    @click:close="data.parent.selectItem(data.item)"
                    class="pe-2 ps-4"
                >
                  <span class="ltr">{{ data.item }}</span>
                  <v-icon
                      class="primary white--text rounded-circle p-1"
                      right
                      small
                      fab
                  >mdi-phone
                  </v-icon>

                </v-chip>
              </template>
            </v-combobox>

            <span class="my-1 d-block">{{ $t('Contact description') }}</span>
            <v-textarea outlined v-model="description" type="text" counter="1000" :rules="descriptionRules" required/>

            <span class="my-1 d-block">{{ $t('Address') }}</span>
            <v-textarea outlined v-model="address" type="text" counter="1000" :rules="descriptionRules" required/>
          </v-form>
        </section>
      </v-expand-transition>

    </v-radio-group>

    <v-footer
        fixed
        app
        paddless
        color="transparent"
        class="pb-2 pt-0"
        style="z-index: 5000;"
    >
      <v-container fluid>
        <v-btn color="primary" large block @click="validate()" :loading="submit_loading" :disabled="submit_loading">
          {{ $t('Submit') }}
        </v-btn>
      </v-container>
    </v-footer>
  </section>

</template>

<script>
import {
  mapNavigationControl,
  mapScaleControl
} from "mapir-vue";
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";

moment.locale("fa", fa);
moment.loadPersian();


export default {
  components: {
    mapNavigationControl,
    mapScaleControl
  },
  data() {
    return {
      map: null,

      submit_loading: false,
      valid: false,

      profile: {
        isClinicNumber: null,
      },

      phoneNumbers: "",
      doctorNumber: "",
      clinicNumber: "",
      description: "",
      address: "",

      coordinates: [51.420296, 35.732379],
      markerCoordinates: [51.420296, 35.732379],

      mapirToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImRhM2UxN2U4MDc1MzRkNTUwZGJlY2YxMmZhYzdmZjRlN2QxMTc4OTY1Nzk4M2NhZGVkZGI5MTNlOGFmNzYwZGQ2NjczYzYyYjAwZWU0YmEzIn0.eyJhdWQiOiIxNzExNSIsImp0aSI6ImRhM2UxN2U4MDc1MzRkNTUwZGJlY2YxMmZhYzdmZjRlN2QxMTc4OTY1Nzk4M2NhZGVkZGI5MTNlOGFmNzYwZGQ2NjczYzYyYjAwZWU0YmEzIiwiaWF0IjoxNjQ1MjY3NTI5LCJuYmYiOjE2NDUyNjc1MjksImV4cCI6MTY0NzY4NjcyOSwic3ViIjoiIiwic2NvcGVzIjpbImJhc2ljIl19.sw_1-g4cTn-J__N6nhChXOJVJNVUu8Spm-vemedB0_oz3hvCJTE4oxaCsCxCuRruUVspmgeGKgBgMh4-xtEhrwB_IVuIf5OrJSA1niTccaXGyr6k5VNpg_dHEPstIOJRzAWKsNyzlf2xUK0PkdulfI2AU0dtWFuuPtR12iZEQpZNEwoaOPD1A6tTb58HWBUoUltKSZk96QtoUlND6uuZKKTwaTYBPPwcL7v7JxDlxGEvSkoNWr-R8rzI48MAepXEBSgeewXIcOZ7-EQVHkbhjLyWu4GKJJo9RBqzEYYcVRewNDhqnAJIdPPyzsG7v5-DI2Mbmgo0lUDaD51XWpm2LA",

      titleRules: [
        v => !!v,
        v => (v && v.length <= 100) || this.$t("The maximum length is 100 characters")
      ],

      descriptionRules: [
        v => !!v,
        v => (v && v.length <= 1000) || this.$t("The maximum length is 1000 characters")
      ],
    }
  },
  methods: {
    getDoctorInformation() {
      apiService
          .getDoctorInfoByMyMobile()
          .then((response) => {
                this.profile = response.data;
                this.clinicNumber = this.profile.clinic.phone;
                this.doctorNumber = this.profile.mobile;
                this.phoneNumbers = this.profile.phone.split(',');
                this.description = this.profile.contactDescription;
                this.address = this.profile.address;
                if (this.profile.lon && this.profile.lat) {
                  this.markerCoordinates = [this.profile.lon, this.profile.lat];
                  this.coordinates = [this.profile.lon, this.profile.lat];
                }
              }
          )
          .catch((err) => {
            this.$swal({
              icon: "warning",
              text: err ? err.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t('OK'),
            })
                .then((result) => {
                  if (result.isConfirmed) {
                    this.$router.go(-1);
                  }
                });

          });
    },
    validate() {
      if (this.profile.isClinicNumber) {
        this.saveContactInfo();
      } else {
        if (this.$refs.form.validate()) {
          this.saveContactInfo();
        }
      }
    }
    ,
    saveContactInfo() {
      this.submit_loading = true;
      let contactInfo = {};
      contactInfo = {
        address: this.address,
        isClinicNumber: this.profile.isClinicNumber,
        contactDescription: this.description,
        phone: this.phoneNumbers.join(','),
        doctorId: this.profile.id,
      }

      apiService.updateDoctorContactInformation(contactInfo)
          .then((response) => {
            this.submit_loading = false;
            if (response.status == 200) {
              this.$router.push("/s/profile");
            }
          })
          .catch((err) => {
            this.submit_loading = false;
            this.$swal({
              icon: "warning",
              text: err ? err.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t('OK'),
            });
          });
    }
    ,

    onMapLoaded(event) {
      var map = event.map;
      setTimeout(() => {
        map.resize();
        map.flyTo({
          center: this.coordinates,
          zoom: 14,
          speed: 0.2,
          curve: 1,
          duration: 5000,
        });
      }, 100);
    }
    ,
    async getlatLng(event) {
      var map = event.map;
      this.markerCoordinates = [event.marker._lngLat.lng, event.marker._lngLat.lat]
      await map.flyTo({
        center: this.markerCoordinates,
        zoom: 14,
        speed: 0.2,
        curve: 1,
        duration: 2000,
      })

    }
    ,
    async mapOnClick(event) {
      // console.log(event.actualEvent.lngLat);
      var map = event.map;
      this.markerCoordinates = [event.actualEvent.lngLat.lng, event.actualEvent.lngLat.lat]
      await map.flyTo({
        center: this.markerCoordinates,
        zoom: 14,
        speed: 0.2,
        curve: 1,
        duration: 2000,
      })
    }
    ,
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);

    this.getDoctorInformation();
  }
  ,
}
</script>
