var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "v-radio-group",
        {
          model: {
            value: _vm.profile.isClinicNumber,
            callback: function ($$v) {
              _vm.$set(_vm.profile, "isClinicNumber", $$v)
            },
            expression: "profile.isClinicNumber",
          },
        },
        [
          _c("v-radio", {
            staticClass: "mb-2",
            attrs: { label: _vm.$t("Clinic contact number"), value: true },
          }),
          _c("v-radio", {
            staticClass: "mt-2",
            attrs: { label: _vm.$t("My own contact number"), value: false },
          }),
          _c("v-expand-transition", [
            _c(
              "section",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.profile.isClinicNumber,
                    expression: "profile.isClinicNumber",
                  },
                ],
              },
              [
                _c(
                  "v-form",
                  {
                    ref: "form",
                    staticClass: "d-flex flex-column mb-4",
                    attrs: { "lazy-validation": "" },
                    model: {
                      value: _vm.valid,
                      callback: function ($$v) {
                        _vm.valid = $$v
                      },
                      expression: "valid",
                    },
                  },
                  [
                    _c("span", { staticClass: "my-1 d-block" }, [
                      _vm._v(_vm._s(_vm.$t("Phone Number"))),
                    ]),
                    _c("v-textarea", {
                      attrs: {
                        outlined: "",
                        readonly: "",
                        type: "text",
                        counter: "1000",
                        required: "",
                      },
                      model: {
                        value: _vm.clinicNumber,
                        callback: function ($$v) {
                          _vm.clinicNumber = $$v
                        },
                        expression: "clinicNumber",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("v-expand-transition", [
            _c(
              "section",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.profile.isClinicNumber,
                    expression: "!profile.isClinicNumber",
                  },
                ],
              },
              [
                _c(
                  "v-form",
                  {
                    ref: "form",
                    staticClass: "d-flex flex-column mb-4",
                    attrs: { "lazy-validation": "" },
                    model: {
                      value: _vm.valid,
                      callback: function ($$v) {
                        _vm.valid = $$v
                      },
                      expression: "valid",
                    },
                  },
                  [
                    _c("span", { staticClass: "my-1 d-block" }, [
                      _vm._v(_vm._s(_vm.$t("Phone Number"))),
                    ]),
                    _c("v-combobox", {
                      attrs: {
                        outlined: "",
                        "single-line": "",
                        rules: [(v) => !!v],
                        required: "",
                        multiple: "",
                        "append-icon": "",
                        delimiters: [",", "-", "."],
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "selection",
                          fn: function (data) {
                            return [
                              _c(
                                "v-chip",
                                _vm._b(
                                  {
                                    key: JSON.stringify(data.item),
                                    staticClass: "pe-2 ps-4",
                                    attrs: {
                                      small: "",
                                      dense: "",
                                      "input-value": data.selected,
                                    },
                                    on: {
                                      "click:close": function ($event) {
                                        return data.parent.selectItem(data.item)
                                      },
                                    },
                                  },
                                  "v-chip",
                                  data.attrs,
                                  false
                                ),
                                [
                                  _c("span", { staticClass: "ltr" }, [
                                    _vm._v(_vm._s(data.item)),
                                  ]),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass:
                                        "primary white--text rounded-circle p-1",
                                      attrs: { right: "", small: "", fab: "" },
                                    },
                                    [_vm._v("mdi-phone ")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.phoneNumbers,
                        callback: function ($$v) {
                          _vm.phoneNumbers = $$v
                        },
                        expression: "phoneNumbers",
                      },
                    }),
                    _c("span", { staticClass: "my-1 d-block" }, [
                      _vm._v(_vm._s(_vm.$t("Contact description"))),
                    ]),
                    _c("v-textarea", {
                      attrs: {
                        outlined: "",
                        type: "text",
                        counter: "1000",
                        rules: _vm.descriptionRules,
                        required: "",
                      },
                      model: {
                        value: _vm.description,
                        callback: function ($$v) {
                          _vm.description = $$v
                        },
                        expression: "description",
                      },
                    }),
                    _c("span", { staticClass: "my-1 d-block" }, [
                      _vm._v(_vm._s(_vm.$t("Address"))),
                    ]),
                    _c("v-textarea", {
                      attrs: {
                        outlined: "",
                        type: "text",
                        counter: "1000",
                        rules: _vm.descriptionRules,
                        required: "",
                      },
                      model: {
                        value: _vm.address,
                        callback: function ($$v) {
                          _vm.address = $$v
                        },
                        expression: "address",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "v-footer",
        {
          staticClass: "pb-2 pt-0",
          staticStyle: { "z-index": "5000" },
          attrs: { fixed: "", app: "", paddless: "", color: "transparent" },
        },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    large: "",
                    block: "",
                    loading: _vm.submit_loading,
                    disabled: _vm.submit_loading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.validate()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }